import * as React from 'react'
import Layout from '/src/components/layout'
import Seo  from '/src/components/seo'
import {Link, graphql} from 'gatsby'
import {container,link} from "/src/components/css/projects-index.module.css"
import { GatsbyImage ,getImage} from 'gatsby-plugin-image'





const Index = ({data}) =>{
    
    return (

        <Layout pageTitle='Projeler'> 
         <div className={container}>
            {
                data.allMdx.nodes.map(node =>(
                  <Link className={link} to={node.frontmatter.slug} >             
                    <GatsbyImage image={getImage(node.frontmatter.main)} alt={node.frontmatter.name}/>
                    <div>
                    <article key={node.id}>
                      <h2>{node.frontmatter.name}</h2>
                    </article>
                    </div>
                  </Link>
                ))
            }    
        </div>   
        </Layout>

    );

}


export const projectQuery = graphql`
query {
  allMdx(sort: {fields: frontmatter___order, order: ASC}) {
    nodes {
      frontmatter {
        date
        job_descp
        location
        name
        related_org
        slug
        main {
          childImageSharp {
            gatsbyImageData(
              quality: 70
              layout: FULL_WIDTH
              aspectRatio: 1.7
              transformOptions: { cropFocus: CENTER}
            )
          }
        }
      }
    }
  }
}`


export const Head =() => <Seo title='Projeler' />

export default Index